<template>
  <div class="information">
    <div class="content">
      <div class="contentTitle">相关联系人</div>
      <div @click="addContent" class="addContent">添加</div>
    </div>
    <a-spin :spinning="spinning">
      <div class="contactClass" v-for="item in contactList" :key="item.id">
        <div class="contactClassLeft">
          <a-avatar
            v-if="item.avatar"
            :src="setAvatar(item.avatar)"
            @click="handleGoToContact(item)"
            class="headerUrl"
          />
          <div v-else @click="handleGoToContact(item)" class="headerText">
            <div v-if="item.name">
              {{ item.name[0] }}
            </div>
          </div>

          <div class="contactsName">{{ item.name }}</div>
        </div>
        <div class="groupDel" @click="handleDel(item)">
          <img src="@/assets/images/box/delete.png" alt="" class="delImg" />
        </div>
      </div>
    </a-spin>
    <iss-contacts-page
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleContacts"
    />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from 'vue';
// import { message } from 'ant-design-vue';
import IssContactsPage from '@/views/contacts/contactAall/detailsPage/contactsPage';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import contactAll from '@/api/contactAll';
import { message, Avatar, Spin } from 'ant-design-vue';
import { setAvatar } from '@/utils';

export default {
  components: {
    IssContactsPage,
    AAvatar: Avatar,
    ASpin: Spin,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const { id } = route.params;
    // console.log('id', id)
    const state = reactive({
      visible: false,
      activeItem: {},
      contactList: [],
      spinning: false,
    });

    const perSonalInfo = () => {
      contactAll
        .queryContactRelations('', {
          userId: store.state.account.user.id,
          contactId: route.params.id,
        })
        .then(res => {
          if (res) {
            state.contactList = res;
            setTimeout(() => {
              state.spinning = false;
            }, 1000);
          }
        });
    };

    watch(
      () => route.params.id,
      value => {
        if (value) {
          perSonalInfo();
        }
      }
    );

    // todo 获取联系人信息,头像,姓名,职位等  route.params.id
    // const getperSonalInfo = (value) => {
    //   contactAll.getPerSonalInfo('', value).then(res => {
    //     if (res) {
    //       console.log('res', res)
    //       // Object.assign(form, res);
    //       // clone.perSonalInfoList = res;
    //     }
    //   });
    // };

    onMounted(() => {
      perSonalInfo();
    });
    return {
      id,
      ...toRefs(state),
      perSonalInfo,
      // getperSonalInfo,
      setAvatar,
      handleDel(item) {
        state.spinning = true;
        contactAll.deleteByRelationId('', item.id).then(() => {
          message.success('操作成功');
          perSonalInfo();
        });
      },
      addContent() {
        state.visible = true;
        state.activeItem = { id: id };
      },
      handleContacts(value) {
        state.visible = value;
        perSonalInfo();
      },
      handleGoToContact(item) {
        router.push(`/contacts/${item.contactSecondaryId}`);
        perSonalInfo();
      },
    };
  },
};
</script>

<style scoped lang="less">
.information {
  margin: 16px 20px;
  //max-height: 662px;
  max-height: calc(100vh - 172px);
  overflow: auto;
  padding-right: 8px;
  padding-left: 2px;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  .contentTitle {
  }

  .addContent {
    color: #ff9634;
    cursor: pointer;
  }
}
.contactClass {
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  .contactClassLeft {
    border: 0.5px solid #eeeeee;
    border-right: none;
    width: 89%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 8px;
    border-radius: 4px;

    .headerUrl {
    }
    .contactsName {
      width: 89%;
    }
  }
  .groupDel {
    border: 0.5px solid #eeeeee;
    width: 11%;
    padding: 6px 8px;
    text-align: center;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
  }
}

.groupCalss {
  background-color: #f5f6fa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 8px 6px;
  margin-top: 10px;
  .groupContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //border: 1px solid #F2F3F7;
    //background: #FFFFFF;
    //padding: 8px 12px;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 8px;

    .groupDel {
      cursor: pointer;
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 12px;
      border-radius: 4px;
    }
    .name {
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 5px 12px;
      border-radius: 4px;
    }
  }
}
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
}
.headerText {
  margin-right: 12px;
  width: 11%;
}
</style>
